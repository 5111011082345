/** @jsx jsx */
import { jsx, Styled } from 'theme-ui';
import { Helmet } from 'react-helmet';

import Layout from '../../../components/Layout';
import Container from '../../../components/Container2';

const ContactThanks = () => {
  return (
    <Layout
      page="contactThanks"
      seo={{
        title: 'Contactez-nous',
      }}
    >
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Container>
        <Styled.h1>Merci!</Styled.h1>
        <Styled.p sx={{ mt: '48px', color: 'muted' }}>
          Nous vous contacterons dans les plus brefs délais.
        </Styled.p>
      </Container>
    </Layout>
  );
};

export default ContactThanks;
